import React, { Suspense } from "react";
import "./App.css";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Comanda from "../Comanda/Comanda";
import Popup from "../Popup/Popup";
import Order from "../Order/Order";

const Service = React.lazy(() => import("../Service/Service"));
const Smi = React.lazy(() => import("../Smi/Smi"));
const Feedback = React.lazy(() => import("../Feedback/Feedback"));
const Footer = React.lazy(() => import("../Footer/Footer"));
const Contacts = React.lazy(() => import("../Contacts/Contacts"));

function App() {
  const [isImagePopoupOpen, setImagePopupOpen] = React.useState(false);
  const [isFormPopoupOpen, setFormPopupOpen] = React.useState(false);
  const [isAlt, setAlt] = React.useState("");
  const [isSrc, setSrc] = React.useState("");

  function closeAllPopups() {
    setImagePopupOpen(false);
    setFormPopupOpen(false);
  }
  function handleImagePopupOpen(e) {
    closeAllPopups(e);
    setImagePopupOpen(true);
    setSrc(e.target.src);
    setAlt(e.target.alt);
  }
  function handleFormPopupOpen(e) {
    closeAllPopups(e);
    setFormPopupOpen(true);
  }
  function handleClickSmiPluse(isNumber, setNumber, n) {
    setNumber(isNumber + 1);
    if (isNumber >= n) {
      setNumber(1);
    }
  }
  function handleClickSmiMinus(isNumber, setNumber, n) {
    setNumber(isNumber - 1);
    if (isNumber <= 1) {
      setNumber(n);
    }
  }
  return (
    <div className="page">
      <Header onClick={handleFormPopupOpen} />
      <Main />
      <Comanda
        onClick={handleImagePopupOpen}
        onClickForm={handleFormPopupOpen}
        handleClickSmiMinus={handleClickSmiMinus}
        handleClickSmiPluse={handleClickSmiPluse}
      />

      <Suspense fallback={<div></div>}>
        <Service onClickForm={handleFormPopupOpen} />
        <Smi
          handleClickSmiMinus={handleClickSmiMinus}
          handleClickSmiPluse={handleClickSmiPluse}
        />
        <Feedback
          handleClickSmiMinus={handleClickSmiMinus}
          handleClickSmiPluse={handleClickSmiPluse}
        />
        <Contacts />
        <Footer />
      </Suspense>
      <Popup
        src={isSrc}
        alt={isAlt}
        onClose={closeAllPopups}
        isFormPopoupOpen={isFormPopoupOpen}
        isImagePopoupOpen={isImagePopoupOpen}
      />
      <Order />
    </div>
  );
}

export default App;
