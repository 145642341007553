import React from "react";
import "./Comanda.css";
import whatsApp from "../../images/whatsapp.svg";
import krasnov from "../../images/krasnov.jpg";
import udostKrasnov from "../../images/udost_krasnov.png";
import melikyan from "../../images/melikyan.jpg";
import vlasov from "../../images/vlasov.jpg";
import udostVlasov from "../../images/udost_vlasov.png";
import vlasovfparf from "../../images/vlasovfparf.jpg";
import vlasovooka from "../../images/vlasovooka.jpg";
import ikonnikov from "../../images/ikonnikov.jpg";
import diplomIkonnikov from "../../images/diplom_ikonnikov.jpg";
import sertIkonnikov from "../../images/sert_ikonnikov.jpg";
import krasnov2 from "../../images/krasnov.jpg";
import melikyan2 from "../../images/melikyan.jpg";
import vlasov2 from "../../images/vlasov.jpg";
import vlasovfparf2 from "../../images/vlasovfparf.jpg";
import vlasovooka2 from "../../images/vlasovooka.jpg";
import ikonnikov2 from "../../images/ikonnikov.jpg";
import diplomIkonnikov2 from "../../images/diplom_ikonnikov.jpg";
import sertIkonnikov2 from "../../images/sert_ikonnikov.jpg";

function Comanda(props) {
  const [isNumber, setNumber] = React.useState(1);
  const [isMore, setMore] = React.useState(false);
  const [isMoreNumber, setMoreNumber] = React.useState("1");
  function more(e) {
    setMore(!isMore);
    setMoreNumber(e.target.id);
    console.log(isMoreNumber);
    console.log(isMore);
    if (!isMore) {
      e.target.innerText = "Скрыть";
    } else {
      e.target.innerText = "Подробнее";
    }
  }
  return (
    <section className="comanda" id="comanda">
      <h2 className="section__title">Команда</h2>

      <div className="comanda__comp">
        <div
          className={
            isNumber === 1 ? "comanda__box comanda__box_select" : "comanda__box"
          }
        >
          <div className="comanda__foto-box">
            <img
              className="comanda__img"
              src={krasnov}
              alt="Краснов Алескандр Сергеевич"
            />
            <div className="comanda__edication">
              <div className="comanda__diplom_small">
                <img
                  className="comanda__diplom"
                  src={udostKrasnov}
                  alt="Удостоверение"
                  onClick={props.onClick}
                />
              </div>
            </div>
          </div>
          <div className="comanda__about">
            <h3 className="comanda__title">Краснов Александр Сергеевич</h3>
            <h4 className="comanda__subtitle">
              Я имею высшее юридическое и экономическое образование. За моей
              спиной один из лучших ВУЗов РФ «Академия МВД РФ». Ранее работал по
              специальности в правоохранительных органах МВД, в частности в
              следственных органах. С 2004 года получив статус адвоката
              занимаюсь адвокатской деятельностью. Соответственно имею большой
              опыт в различных отраслях права.
            </h4>
            <p className="comanda__text">
              За моей спиной почти 20 лет практики в сфере защиты бизнесменов от
              уголовного преследования, по так называемым «экономическим»
              составам преступлений, и по другим сложным и непростым уголовным
              делам. Мой опыт в качестве следователя, дает понимание как
              действует вся система наших «правоохранительных» органов. И зная
              систему из внутри, я как никто, могу ей противостоять, как в
              процессуальном, так и в психологическом аспекте, тех непростых
              особенностей такого жесткого правоприменения. Причем это,
              результативное противостояние происходит на достаточно высоком
              профессиональном уровне, невзирая при этом ни на связи, ни на
              коррупцию в этой системе.
            </p>
            <p className="comanda__text">
              Стану для вас надежным правовым защитником, успешно решающим
              задачи любой сложности – семейные споры и арбитраж, гражданские и
              уголовные дела.
            </p>
            <div className="comanda__button-box">
              <button
                className="comanda__button"
                type="button"
                onClick={props.onClickForm}
              >
                Проконсультироваться
              </button>
              <div className="comanda__arrow-box">
                <button
                  className="comanda__arrow comanda__arrow_left"
                  type="button"
                  onClick={() =>
                    props.handleClickSmiMinus(isNumber, setNumber, 4)
                  }
                ></button>
                <button
                  className="comanda__arrow comanda__arrow_right"
                  type="button"
                  onClick={() =>
                    props.handleClickSmiPluse(isNumber, setNumber, 4)
                  }
                ></button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isNumber === 2 ? "comanda__box comanda__box_select" : "comanda__box"
          }
        >
          <div className="comanda__foto-box">
            <img
              className="comanda__img"
              src={melikyan}
              alt="Меликян Гаянэ Арамовна"
            />
            <div className="comanda__edication">
              {/* <div className="img__bg"><img className="comanda__diplom" src="" alt="" onClick={props.onClick}></div> */}
            </div>
          </div>
          <div className="comanda__about">
            <h3 className="comanda__title">Меликян Гаянэ Арамовна</h3>
            <h4 className="comanda__subtitle">
              Окончила юридический факультет Волгоградского государственного
              университета в 2010 г. Стаж работы 11 лет. После окончания
              университета работала помощником судьи почти 7 лет, после чего
              судебным юристом.
            </h4>
            <p className="comanda__text">
              Представление интересов как юридических, так и физических лиц в
              арбитражных судах, судах общей юрисдикции и мировых судах по
              гражданским и административным делам. За время деятельности
              провела более 200 дел — в 80% решение было вынесено в пользу
              Клиентов.
            </p>
            <p className="comanda__text">
              Ведение претензионной работы. Более 60% всех конфликтов
              урегулировала в досудебном порядке.
            </p>
            <p className="comanda__text">
              Проведение согласования и экспертизы договоров: аренды, подряда,
              оказания услуг, купли-продажи, страхования и других, сопровождение
              различных сделок.
            </p>
            <p className="comanda__text">
              Проведение консультаций с Клиентами.
            </p>
            <p className="comanda__text">
              Одной из наиболее важных побед в своей практике считаю отмену
              судебных актов нижестоящих судов в Верховном Суде РФ по
              определенному сложному делу.{" "}
            </p>
            <p className="comanda__text"></p>
            <div className="comanda__button-box">
              <button
                className="comanda__button"
                type="button"
                onClick={props.onClickForm}
              >
                Проконсультироваться
              </button>
              <div className="comanda__arrow-box">
                <button
                  className="comanda__arrow comanda__arrow_left"
                  type="button"
                  onClick={() =>
                    props.handleClickSmiMinus(isNumber, setNumber, 4)
                  }
                ></button>
                <button
                  className="comanda__arrow comanda__arrow_right"
                  type="button"
                  onClick={() =>
                    props.handleClickSmiPluse(isNumber, setNumber, 4)
                  }
                ></button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isNumber === 3 ? "comanda__box comanda__box_select" : "comanda__box"
          }
        >
          <div className="comanda__foto-box">
            <img
              className="comanda__img"
              src={vlasov}
              alt="Власов Василий Алексеевич"
            />
            <div className="comanda__edication">
              <div className="comanda__diplom_small">
                <img
                  className="comanda__diplom"
                  src={udostVlasov}
                  alt="Удостоверение"
                  onClick={props.onClick}
                />
              </div>
              <div className="comanda__diplom_big">
                <img
                  className="comanda__diplom"
                  src={vlasovfparf}
                  alt="Грамота ФПА РФ"
                  onClick={props.onClick}
                />
              </div>
              <div className="comanda__diplom_big">
                <img
                  className="comanda__diplom"
                  src={vlasovooka}
                  alt="Грамота ООКА"
                  onClick={props.onClick}
                />
              </div>
            </div>
          </div>
          <div className="comanda__about">
            <h3 className="comanda__title">Власов Василий Алексеевич</h3>
            <h4 className="comanda__subtitle">
              С 2003 г. по настоящее время является адвокатом. С 2020 года
              состоит в реестре адвокатов города Москвы. С 2008 по 2018 гг. –
              Председатель Президиума Омской Региональной общественной
              организации «Защита прав потребителей». С 2016 по 2018 гг. - член
              Общественного совета при министерстве экономики Омской области
              (Комитет "Развитие малого и среднего предпринимательства и
              инвестиционная политика").
            </h4>
            <p className="comanda__text">
              За время работы приобрёл уверенные навыки по организации,
              оптимизации и контролю работы различных юридических департаментов,
              которые непосредственно применял на практике, являясь бессменным
              Председателем Президиума на протяжении 10 лет.
            </p>
            <p className="comanda__text">
              Осуществляю постоянное взаимодействие с клиентами, управление
              проектами, определение и назначение исполнителей поступающих от
              клиентов задач, согласование планов работ, проверка/корректировка
              результатов выполнения поставленных задач.
            </p>
            <p className="comanda__text">
              Большой опыт ведения дел в арбитражных судах и общей юрисдикции,
              уголовных и административных дел.
            </p>
            <div className="comanda__button-box">
              <button
                className="comanda__button"
                type="button"
                onClick={props.onClickForm}
              >
                Проконсультироваться
              </button>
              <div className="comanda__arrow-box">
                <button
                  className="comanda__arrow comanda__arrow_left"
                  type="button"
                  onClick={() =>
                    props.handleClickSmiMinus(isNumber, setNumber, 4)
                  }
                ></button>
                <button
                  className="comanda__arrow comanda__arrow_right"
                  type="button"
                  onClick={() =>
                    props.handleClickSmiPluse(isNumber, setNumber, 4)
                  }
                ></button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            isNumber === 4 ? "comanda__box comanda__box_select" : "comanda__box"
          }
        >
          <div className="comanda__foto-box">
            <img
              className="comanda__img"
              src={ikonnikov}
              alt="Иконников Алексей Анатольевич"
            />
            <div className="comanda__edication">
              <div className="comanda__diplom_big">
                <img
                  className="comanda__diplom"
                  src={diplomIkonnikov}
                  alt="Диплом"
                  onClick={props.onClick}
                />
              </div>
              <div className="comanda__diplom_big">
                <img
                  className="comanda__diplom"
                  src={sertIkonnikov}
                  alt="Сертификат"
                  onClick={props.onClick}
                />
              </div>
            </div>
          </div>
          <div className="comanda__about">
            <h3 className="comanda__title">Иконников Алексей Анатольевич</h3>
            <h4 className="comanda__subtitle">
              Выпускник Волгоградского Юридического Института, специалист
              практически всех отраслей права, юридический стаж с 2012 года.
              Имеет опыт службы в органах дознания МВД РФ, который стал
              незаменимым при осуществлении юридической деятельности в настоящее
              время.
            </h4>
            <p className="comanda__text">
              Консультирую по всем категориям правовых вопросов. Сопровождаю
              ведение дел на всех стадиях и во всех судебных и исполнительных
              инстанциях до получения результата. Являюсь очень ответственным,
              коммуникабельным и нацеленным на результат юристом и судебным
              представителем.
            </p>
            <p className="comanda__text">
              Являюсь действующим помощником депутата от партии КПРФ, благодаря
              чему, в исключительных ситуациях имею реальную возможность
              взаимодействовать напрямую со СМИ.
            </p>
            <p className="comanda__text">
              Среди личных достижений могу отметить неоднократное возвращение
              водительского удостоверения после лишения, отмену права
              собственности жилого помещения за ДГИ города Москвы и признание
              права собственности за клиентом, за пределами сроков исковой
              давности, неоднократные отмены решений и определений судов в
              апелляционных инстанциях.
            </p>
            <div className="comanda__button-box">
              <button
                className="comanda__button"
                type="button"
                onClick={props.onClickForm}
              >
                Проконсультироваться
              </button>
              <div className="comanda__arrow-box">
                <button
                  className="comanda__arrow comanda__arrow_left"
                  type="button"
                  onClick={() =>
                    props.handleClickSmiMinus(isNumber, setNumber, 4)
                  }
                ></button>
                <button
                  className="comanda__arrow comanda__arrow_right"
                  type="button"
                  onClick={() =>
                    props.handleClickSmiPluse(isNumber, setNumber, 4)
                  }
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="comanda__mob">
        <div
          className={
            isNumber === 1 ? "comanda__box comanda__box_select" : "comanda__box"
          }
        >
          <img
            className="comanda__img"
            src={krasnov2}
            alt="Краснов Алескандр Сергеевич"
          />
          <div className="comanda__about">
            <h3 className="comanda__title">Краснов Александр Сергеевич</h3>
            <h4
              className={
                isMore
                  ? "comanda__subtitle"
                  : "comanda__subtitle comanda__subtitle-mob"
              }
            >
              Я имею высшее юридическое и экономическое образование. За моей
              спиной один из лучших ВУЗов РФ «Академия МВД РФ». Ранее работал по
              специальности в правоохранительных органах МВД, в частности в
              следственных органах. С 2004 года получив статус адвоката
              занимаюсь адвокатской деятельностью. Соответственно имею большой
              опыт в различных отраслях права.
            </h4>
            <div
              className={
                isMore && isMoreNumber === "1"
                  ? "comanda__text-box comanda__text-box_active"
                  : "comanda__text-box"
              }
            >
              <p className="comanda__text">
                За моей спиной почти 20 лет практики в сфере защиты бизнесменов
                от уголовного преследования, по так называемым «экономическим»
                составам преступлений, и по другим сложным и непростым уголовным
                делам. Мой опыт в качестве следователя, дает понимание как
                действует вся система наших «правоохранительных» органов. И зная
                систему из внутри, я как никто, могу ей противостоять, как в
                процессуальном, так и в психологическом аспекте, тех непростых
                особенностей такого жесткого правоприменения. Причем это,
                результативное противостояние происходит на достаточно высоком
                профессиональном уровне, невзирая при этом ни на связи, ни на
                коррупцию в этой системе.
              </p>
              <p className="comanda__text">
                Стану для вас надежным правовым защитником, успешно решающим
                задачи любой сложности – семейные споры и арбитраж, гражданские
                и уголовные дела.
              </p>
            </div>
            <button
              type="button"
              className="comanda__more"
              id="1"
              onClick={more}
            >
              Подробнее
            </button>
          </div>
          <div className="comanda__edication">
            <div className="comanda__diplom_small">
              <img
                className="comanda__diplom"
                src={udostKrasnov}
                alt="Удостоверение"
                onClick={props.onClick}
              />
            </div>
          </div>
          <div className="comanda__button-box">
            <a
              href="whatsapp://send?phone=%2b79154858825"
              className="comanda__button"
            >
              <img
                className="header__social-img whatsApp"
                alt="WhatsApp"
                src={whatsApp}
              />
              консультация
            </a>
            <div className="comanda__arrow-box">
              <button
                className="comanda__arrow comanda__arrow_left"
                type="button"
                onClick={() =>
                  props.handleClickSmiMinus(isNumber, setNumber, 4)
                }
              ></button>
              <button
                className="comanda__arrow comanda__arrow_right"
                type="button"
                onClick={() =>
                  props.handleClickSmiPluse(isNumber, setNumber, 4)
                }
              ></button>
            </div>
          </div>
        </div>
        <div
          className={
            isNumber === 2 ? "comanda__box comanda__box_select" : "comanda__box"
          }
        >
          <img
            className="comanda__img"
            src={melikyan2}
            alt="Меликян Гаянэ Арамовна"
          />
          <div className="comanda__about">
            <h3 className="comanda__title">Меликян Гаянэ Арамовна</h3>
            <h4
              className={
                isMore
                  ? "comanda__subtitle"
                  : "comanda__subtitle comanda__subtitle-mob"
              }
            >
              Окончила юридический факультет Волгоградского государственного
              университета в 2010 г. Стаж работы 11 лет. После окончания
              университета работала помощником судьи почти 7 лет, после чего
              судебным юристом.
            </h4>
            <div
              className={
                isMore && isMoreNumber === "2"
                  ? "comanda__text-box comanda__text-box_active"
                  : "comanda__text-box"
              }
            >
              <p className="comanda__text">
                Представление интересов как юридических, так и физических лиц в
                арбитражных судах, судах общей юрисдикции и мировых судах по
                гражданским и административным делам. За время деятельности
                провела более 200 дел — в 80% решение было вынесено в пользу
                Клиентов.
              </p>
              <p className="comanda__text">
                Ведение претензионной работы. Более 60% всех конфликтов
                урегулировала в досудебном порядке.
              </p>
              <p className="comanda__text">
                Проведение согласования и экспертизы договоров: аренды, подряда,
                оказания услуг, купли-продажи, страхования и других,
                сопровождение различных сделок.
              </p>
              <p className="comanda__text">
                Проведение консультаций с Клиентами.
              </p>
              <p className="comanda__text">
                Одной из наиболее важных побед в своей практике считаю отмену
                судебных актов нижестоящих судов в Верховном Суде РФ по
                определенному сложному делу.{" "}
              </p>
            </div>
            <button
              type="button"
              className="comanda__more"
              id="2"
              onClick={more}
            >
              Подробнее
            </button>
          </div>
          <div className="comanda__edication">
            {/* <div className="img__bg"><img className="comanda__diplom" src="" alt="" onClick={props.onClick}/></div> */}
          </div>
          <div className="comanda__button-box">
            <a
              href="whatsapp://send?phone=%2b79154858825"
              className="comanda__button"
            >
              <img
                className="header__social-img whatsApp"
                alt="WhatsApp"
                src={whatsApp}
              />
              консультация
            </a>
            <div className="comanda__arrow-box">
              <button
                className="comanda__arrow comanda__arrow_left"
                type="button"
                onClick={() =>
                  props.handleClickSmiMinus(isNumber, setNumber, 4)
                }
              ></button>
              <button
                className="comanda__arrow comanda__arrow_right"
                type="button"
                onClick={() =>
                  props.handleClickSmiPluse(isNumber, setNumber, 4)
                }
              ></button>
            </div>
          </div>
        </div>
        <div
          className={
            isNumber === 3 ? "comanda__box comanda__box_select" : "comanda__box"
          }
        >
          <img
            className="comanda__img"
            src={vlasov2}
            alt="Власов Василий Алексеевич"
          />
          <div className="comanda__about">
            <h3 className="comanda__title">Власов Василий Алексеевич</h3>
            <h4
              className={
                isMore
                  ? "comanda__subtitle"
                  : "comanda__subtitle comanda__subtitle-mob"
              }
            >
              С 2003 г. по настоящее время является адвокатом. С 2020 года
              состоит в реестре адвокатов города Москвы. С 2008 по 2018 гг. –
              Председатель Президиума Омской Региональной общественной
              организации «Защита прав потребителей». С 2016 по 2018 гг. - член
              Общественного совета при министерстве экономики Омской области
              (Комитет "Развитие малого и среднего предпринимательства и
              инвестиционная политика").
            </h4>
            <div
              className={
                isMore && isMoreNumber === "3"
                  ? "comanda__text-box comanda__text-box_active"
                  : "comanda__text-box"
              }
            >
              <p className="comanda__text">
                За время работы приобрёл уверенные навыки по организации,
                оптимизации и контролю работы различных юридических
                департаментов, которые непосредственно применял на практике,
                являясь бессменным Председателем Президиума на протяжении 10
                лет.
              </p>
              <p className="comanda__text">
                Осуществляю постоянное взаимодействие с клиентами, управление
                проектами, определение и назначение исполнителей поступающих от
                клиентов задач, согласование планов работ,
                проверка/корректировка результатов выполнения поставленных
                задач.
              </p>
              <p className="comanda__text">
                Большой опыт ведения дел в арбитражных судах и общей юрисдикции,
                уголовных и административных дел.
              </p>
            </div>
            <button
              type="button"
              className="comanda__more"
              id="3"
              onClick={more}
            >
              Подробнее
            </button>
          </div>
          <div className="comanda__edication">
            <div className="comanda__diplom_small">
              <img
                className="comanda__diplom"
                src={udostVlasov}
                alt="Удостоверение"
                onClick={props.onClick}
              />
            </div>
            <div className="comanda__diplom_small">
              <img
                className="comanda__diplom"
                src={vlasovfparf2}
                alt="Грамота ФПА РФ"
                onClick={props.onClick}
              />
            </div>
            <div className="comanda__diplom_small">
              <img
                className="comanda__diplom"
                src={vlasovooka2}
                alt="Грамота ООКА"
                onClick={props.onClick}
              />
            </div>
          </div>
          <div className="comanda__button-box">
            <a
              href="whatsapp://send?phone=%2b79154858825"
              className="comanda__button"
            >
              <img
                className="header__social-img whatsApp"
                alt="WhatsApp"
                src={whatsApp}
              />
              консультация
            </a>
            <div className="comanda__arrow-box">
              <button
                className="comanda__arrow comanda__arrow_left"
                type="button"
                onClick={() =>
                  props.handleClickSmiMinus(isNumber, setNumber, 4)
                }
              ></button>
              <button
                className="comanda__arrow comanda__arrow_right"
                type="button"
                onClick={() =>
                  props.handleClickSmiPluse(isNumber, setNumber, 4)
                }
              ></button>
            </div>
          </div>
        </div>
        <div
          className={
            isNumber === 4 ? "comanda__box comanda__box_select" : "comanda__box"
          }
        >
          <img
            className="comanda__img"
            src={ikonnikov2}
            alt="Иконников Алексей Анатольевич"
          />
          <div className="comanda__about">
            <h3 className="comanda__title">Иконников Алексей Анатольевич</h3>
            <h4
              className={
                isMore
                  ? "comanda__subtitle"
                  : "comanda__subtitle comanda__subtitle-mob"
              }
            >
              Выпускник Волгоградского Юридического Института, специалист
              практически всех отраслей права, юридический стаж с 2012 года.
              Имеет опыт службы в органах дознания МВД РФ, который стал
              незаменимым при осуществлении юридической деятельности в настоящее
              время.
            </h4>
            <div
              className={
                isMore && isMoreNumber === "4"
                  ? "comanda__text-box comanda__text-box_active"
                  : "comanda__text-box"
              }
            >
              <p className="comanda__text">
                Консультирую по всем категориям правовых вопросов. Сопровождаю
                ведение дел на всех стадиях и во всех судебных и исполнительных
                инстанциях до получения результата. Являюсь очень ответственным,
                коммуникабельным и нацеленным на результат юристом и судебным
                представителем.
              </p>
              <p className="comanda__text">
                Являюсь действующим помощником депутата от партии КПРФ,
                благодаря чему, в исключительных ситуациях имею реальную
                возможность взаимодействовать напрямую со СМИ.
              </p>
              <p className="comanda__text">
                Среди личных достижений могу отметить неоднократное возвращение
                водительского удостоверения после лишения, отмену права
                собственности жилого помещения за ДГИ города Москвы и признание
                права собственности за клиентом, за пределами сроков исковой
                давности, неоднократные отмены решений и определений судов в
                апелляционных инстанциях.
              </p>
            </div>
            <button
              type="button"
              className="comanda__more"
              id="4"
              onClick={more}
            >
              Подробнее
            </button>
          </div>
          <div className="comanda__edication">
            <div className="comanda__diplom_big">
              <img
                className="comanda__diplom"
                src={diplomIkonnikov2}
                alt="Диплом"
                onClick={props.onClick}
              />
            </div>
            <div className="comanda__diplom_big">
              <img
                className="comanda__diplom"
                src={sertIkonnikov2}
                alt="Сертификат"
                onClick={props.onClick}
              />
            </div>
          </div>
          <div className="comanda__button-box">
            <a
              href="whatsapp://send?phone=%2b79154858825"
              className="comanda__button"
            >
              <img
                className="header__social-img whatsApp"
                alt="WhatsApp"
                src={whatsApp}
              />
              консультация
            </a>
            <div className="comanda__arrow-box">
              <button
                className="comanda__arrow comanda__arrow_left"
                type="button"
                onClick={() =>
                  props.handleClickSmiMinus(isNumber, setNumber, 4)
                }
              ></button>
              <button
                className="comanda__arrow comanda__arrow_right"
                type="button"
                onClick={() =>
                  props.handleClickSmiPluse(isNumber, setNumber, 4)
                }
              ></button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Comanda;
