import "./Main.css";
import Form from "../Form/Form";

function Main() {
  return (
    <section className="main">
      <div className="main__bg">
        <h1 className="main__title">
          Бесплатная первичная юридическая помощь в Москве и МО
        </h1>
        <Form name={"main__form header__form-comp"}>
          <h4 className="form__subtitle">
            Оставьте заявку и мы бесплатно проконсультируем и составим план
            дальнейших действий
          </h4>
        </Form>
        <Form name={"header__form-mob"}>
          <h4 className="form__subtitle">
            Оставьте заявку на бесплатную консультацию
          </h4>
        </Form>
      </div>
    </section>
  );
}

export default Main;
