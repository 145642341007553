import "./Form.css";
import React from "react";

function Form(props) {
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [error, setError] = React.useState("");
  const regex = /['a-z''-''/'':']/g;
  const regex3 = /['0-9']/g;
  const regex4 = /['а-я']/g;
  const regex2 = /(http|https):\/\/([\w/.!&#@-]*).([a-z]{2,})([\w/.!&#@-]*)/gi;

  function handleChange(e) {
    if (
      regex.test(e.target.value) ||
      regex2.test(e.target.value) ||
      regex3.test(e.target.value)
    ) {
      setPhone("");
      setError(
        "Введены запрещенные символы. Используйте только русские буквы."
      );
      setName(e.target.value);
    } else {
      setName(e.target.value);
    }
  }
  function handleChangePhone(e) {
    if (regex.test(e.target.value) || regex2.test(e.target.value)) {
      setError(
        "Номер телефона введен неверно! Напишите номер в формате: 8 888 88 88 или 7 888 88 88"
      );
      setName("");
      setPhone(e.target.value);
    } else {
      setPhone(e.target.value);
    }
  }

  return (
    <form
      className={props.name}
      action="../../post/form1.php"
      method="POST"
      encType="multipart/form-data"
    >
      {props.children}
      <div className={props.column === true ? "footer__form-box" : "form__box"}>
        <div className="form__input-box">
          <input
            onChange={handleChange}
            className={
              props.name === "header__form-mob"
                ? "header__input form__input_name"
                : props.name === "footer__form" ||
                  props.name ===
                    "popup__container popup__form popup__container_active"
                ? "footer__input form__input_name"
                : "form__input form__input_name"
            }
            name="name"
            type="text"
            placeholder="Имя"
            autoComplete="off"
            maxLength="30"
            required
          />
          <span
            className={
              regex.test(name) || regex2.test(name) || regex3.test(name)
                ? "form__error form__error_active"
                : "form__error"
            }
          >
            {error}
          </span>
        </div>
        <div className="form__input-box">
          <input
            onChange={handleChangePhone}
            className={
              props.name === "header__form-mob"
                ? "header__input form__input_tel"
                : props.name === "footer__form" ||
                  props.name ===
                    "popup__container popup__form popup__container_active"
                ? "footer__input form__input_tel"
                : "form__input form__input_tel"
            }
            name="tel"
            type="tel"
            placeholder="Телефон: 8 999 99 99"
            autoComplete="off"
            required
            minLength="8"
          />
          <span
            className={
              regex.test(phone) || regex2.test(phone)
                ? "form__error form__error_active"
                : "form__error"
            }
          >
            {error}
          </span>
        </div>
        {props.text === true ? (
          <div className="form__input-box">
            <input
              onChange={handleChange}
              className={
                props.name === "header__form-mob"
                  ? "header__input form__input_name"
                  : props.name === "footer__form" ||
                    props.name ===
                      "popup__container popup__form popup__container_active"
                  ? "footer__input form__input_name"
                  : "form__input form__input_name"
              }
              name="problem"
              type="text"
              placeholder="Проблема (кратко)"
              autoComplete="off"
              maxLength="100"
              required
            />
            <span
              className={
                regex.test(name) || regex2.test(name) || regex3.test(name)
                  ? "form__error form__error_active"
                  : "form__error"
              }
            >
              {error}
            </span>
          </div>
        ) : (
          <></>
        )}
        <button
          className="form__button"
          type="submit"
          value="Отправить"
          disabled={
            regex.test(phone) ||
            regex2.test(phone) ||
            regex4.test(phone) ||
            regex.test(name) ||
            regex2.test(name) ||
            regex3.test(name)
          }
        >
          Отправить
        </button>
      </div>
    </form>
  );
}

export default Form;
