import Form from "../Form/Form";
import "./Popup.css";

function Popup(props) {
  return (
    <section
      onClick={(e) => e.currentTarget === e.target && props.onClose()}
      className={
        props.isFormPopoupOpen || props.isImagePopoupOpen
          ? " popup popup_on"
          : "popup"
      }
    >
      <figure
        className={
          props.isImagePopoupOpen
            ? "popup__container popup__container_figure popup__container_active"
            : "popup__container popup__container_figure"
        }
      >
        <button
          className="popup__close"
          type="button"
          onClick={props.onClose}
        ></button>
        <img className="popup__img" src={props.src} alt={props.alt} />
        <figcaption className="popup__text">{props.alt}</figcaption>
      </figure>
      <Form
        column={true}
        name={
          props.isFormPopoupOpen
            ? "popup__container popup__form popup__container_active"
            : "popup__container popup__form"
        }
      >
        <button
          className="popup__close popup__close_form"
          type="button"
          onClick={props.onClose}
        ></button>
        <h2 className="popup-form__title">
          Оставьте номер телефона и мы свяжемся с вами в течение 5 минут
        </h2>
      </Form>
    </section>
  );
}

export default Popup;
