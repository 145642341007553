import "./Order.css";
import React from "react";
import male from "../../images/male.svg";
import female from "../../images/female.svg";
import message from "../../sound/massege.mp3";
function Order(props) {
  const [randomNumber, setRandomNumber] = React.useState(0);
  const [randomName, setRandomName] = React.useState(0);
  const [orderOpen, setOrderOpen] = React.useState(false);
  const audio = new Audio(message);
  audio.load();
  const names = [
    { name: "Голубев Алексей", gender: "m" },
    { name: "Ефремов Владислав", gender: "m" },
    { name: "Доронин Влас", gender: "m" },
    { name: "Киселёв Богдан", gender: "m" },
    { name: "Воробьёв Иван", gender: "m" },
    { name: "Большаков Владимир", gender: "m" },
    { name: "Кузьмин Бронислав", gender: "m" },
    { name: "Поляков Тарас", gender: "m" },
    { name: "Семёнов Василий", gender: "m" },
    { name: "Кошелев Глеб", gender: "m" },
    { name: "Гущин Михаил", gender: "m" },
    { name: "Казаков Егор", gender: "m" },
    { name: "Соболев Максим", gender: "m" },
    { name: "Шилов Моисей", gender: "m" },
    { name: "Осипов Вениамин", gender: "m" },
    { name: "Цветков Елисей", gender: "m" },
    { name: "Горшков Эрнест", gender: "m" },
    { name: "Горбунов Семен", gender: "m" },
    { name: "Медведев Власий", gender: "m" },
    { name: "Тарасов Осип", gender: "m" },
    { name: "Самсонов Виктор", gender: "m" },
    { name: "Борисов Зиновий", gender: "m" },
    { name: "Орехов Варлам", gender: "m" },
    { name: "Емельянов Тарас", gender: "m" },
    { name: "Молчанов Ипполит", gender: "m" },
    { name: "Ширяев Никифор", gender: "m" },
    { name: "Денисов Осип", gender: "m" },
    { name: "Пестов Виталий", gender: "m" },
    { name: "Кудряшов Артем", gender: "m" },
    { name: "Потапов Данил", gender: "m" },
    { name: "Артемьев Артем", gender: "m" },
    { name: "Лукин Юрий", gender: "m" },
    { name: "Туров Ян", gender: "m" },
    { name: "Никонов Мирослав", gender: "m" },
    { name: "Андреев Аверьян", gender: "m" },
    { name: "Гуляев Матвей", gender: "m" },
    { name: "Моисеев Дмитрий", gender: "m" },
    { name: "Кузнецов Прохор", gender: "m" },
    { name: "Ермаков Дмитрий", gender: "m" },
    { name: "Владимиров Александр", gender: "m" },
    { name: "Кузьмин Яков", gender: "m" },
    { name: "Воробьёв Сергей", gender: "m" },
    { name: "Котов Алексей", gender: "m" },
    { name: "Баранов Петр", gender: "m" },
    { name: "Дмитриев Вилен", gender: "m" },
    { name: "Лукин Виталий Антонович", gender: "m" },
    { name: "Пахомов Игнатий Михаилович", gender: "m" },
    { name: "Чернова Лидия Валентиновна", gender: "f" },
    { name: "Жукова Майя Юрьевна", gender: "f" },
    { name: "Ситникова Стелла Святославовна", gender: "f" },
    { name: "Новикова Грета Платоновна", gender: "f" },
    { name: "Журавлёва Аделина Егоровна", gender: "f" },
    { name: "Сысоева Милиса Филипповна", gender: "f" },
    { name: "Копылова Аэлита Альбертовна", gender: "f" },
    { name: "Самойлова Тала Наумовна", gender: "f" },
    { name: "Костина Андриана Николаевна", gender: "f" },
    { name: "Шубина Диша Данииловна", gender: "f" },
    { name: "Шестакова Анфиса Владимировна", gender: "f" },
    { name: "Колесникова Залина Ильяовна", gender: "f" },
    { name: "Козлова Алика Гордеевна", gender: "f" },
    { name: "Бобылёва Санта Федосеевна", gender: "f" },
    { name: "Боброва Элла Глебовна", gender: "f" },
    { name: "Авдеева Муза Еремеевна", gender: "f" },
    { name: "Сафонова Альберта Робертовна", gender: "f" },
    { name: "Орехова Ралина Давидовна", gender: "f" },
    { name: "Мышкина Илона Созоновна", gender: "f" },
    { name: "Ковалёва Владлена Ильяовна", gender: "f" },
    { name: "Капустина Наоми Аркадьевна", gender: "f" },
    { name: "Назарова Карина Владимировна", gender: "f" },
    { name: "Белоусова Доминика Германновна", gender: "f" },
    { name: "Кудрявцева Марина Альбертовна", gender: "f" },
    { name: "Воронова Аделина Дамировна", gender: "f" },
    { name: "Аксёнова Алла", gender: "f" },
    { name: "Дмитриева Каролина", gender: "f" },
    { name: "Лихачёва Габриэлла", gender: "f" },
    { name: "Одинцова Элла", gender: "f" },
    { name: "Соболева Эдуарда", gender: "f" },
    { name: "Савина Мериса", gender: "f" },
    { name: "Корнилова Лада", gender: "f" },
    { name: "Белозёрова Владислава", gender: "f" },
    { name: "Бобылёва Рая", gender: "f" },
    { name: "Щукина Любовь", gender: "f" },
    { name: "Дроздова Виоланта", gender: "f" },
    { name: "Карпова Анжелика", gender: "f" },
    { name: "Субботина Лилия", gender: "f" },
    { name: "Кабанова Эдита", gender: "f" },
    { name: "Горбунова Изабелла", gender: "f" },
    { name: "Галкина Римма", gender: "f" },
    { name: "Третьякова Таира", gender: "f" },
    { name: "Мышкина Юлия", gender: "f" },
    { name: "Кабанова Ольга", gender: "f" },
    { name: "Кудряшова Клавдия", gender: "f" },
    { name: "Трофимова Надежда", gender: "f" },
    { name: "Артемьева Анна", gender: "f" },
  ];
  function orderClose() {
    setOrderOpen(false);
  }
  function orderRandom() {
    setRandomNumber(Math.floor(Math.random() * (60 - 0) + 0));
    setRandomName(Math.floor(Math.random() * (60 - 0) + 0));
    setOrderOpen(true);
    setTimeout(() => {
      setOrderOpen(false);
      setRandomNumber(0);
    }, 15000);
    setTimeout(orderRandom, 20000);
  }
  function setRandom() {
    setRandomNumber(5);
    setRandomName(Math.floor(Math.random() * (60 - 0) + 0));
    setOrderOpen(true);
    setTimeout(() => {
      setOrderOpen(false);
      setRandomNumber(0);
    }, 15000);
  }
  React.useEffect(() => {
    const elementaryTimeout = setTimeout(setRandom, 35000);
    return () => clearTimeout(elementaryTimeout);
  }, []);
  React.useEffect(() => {
    const interval = setTimeout(orderRandom, 51000);
    return () => clearTimeout(interval);
  }, []);
  return (
    <section
      className={
        randomNumber % 5 === 0 && randomNumber !== 0 && orderOpen
          ? "order order__active"
          : "order"
      }
    >
      <audio id="beep" autoPlay preload loop>
        <source src={message} type="audio/mpeg" />
      </audio>
      <img
        className="order__img"
        alt="clients"
        src={names[randomName].gender === "f" ? female : male}
      />
      <div className="order__box">
        <h5 className="order__title">Новый заказ на сайте</h5>
        <p className="order__name">
          {names[randomName].name}{" "}
          {names[randomName].gender === "f"
            ? "заказала бесплатную консультацию"
            : "заказал бесплатную консультацию"}
        </p>
      </div>
      <button
        className="order__close"
        type="button"
        onClick={orderClose}
      ></button>
    </section>
  );
}

export default Order;
