import logo from "../../images/logo.png";
import whatsApp from "../../images/whatsapp.svg";
import viber from "../../images/viber.svg";
import phone from "../../images/phone.svg";

import "./Header.css";

function Header(props) {
  return (
    <header className="header__sidebar">
      <div className="header__box">
        <img className="header__logo" src={logo} alt="Логотип Союз Адвокатов" />
        <p className="header__logo-text">Союз Адвокатов</p>
      </div>
      <div className="header__box">
        <div className="header__messenger">
          <a
            href="whatsapp://send?phone=%2b79154858825"
            className="header__social-link"
          >
            <img
              className="header__social-img whatsApp"
              alt="WhatsApp"
              src={whatsApp}
            />
          </a>
          <a
            href="viber://chat?number=%2B79154858825"
            className="header__social-link"
          >
            <img className="header__social-img viber" src={viber} alt="Viber" />
          </a>
        </div>
        <div className="header__contacts">
          <a href="tel:+79154858825" className="header__tel-link">
            <img className="header__tel-img" src={phone} alt="phone" />
            <p className="header__tel-text">8 915 485 88 25</p>
          </a>
          <button
            className="header__back"
            type="button"
            onClick={props.onClick}
          >
            Заказать звонок
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
